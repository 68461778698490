const localStoragePlugin = store => {
  // called when the store is initialized
  store.subscribe((mutation, { appConfig, portal }) => {
    // called after every mutation.
    // The mutation comes in the format of `{ type, payload }`.

    localStorage.setItem('appConfig', JSON.stringify(appConfig))
    if (mutation.type === 'portal/UPDATE_USER_DATA') localStorage.setItem('userData', JSON.stringify(portal.userData))
  })
}
export default localStoragePlugin
