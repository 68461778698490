import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/libs/i18n'
// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getHomeRouteForLoggedInUser } from '@/auth/utils'
import { getUiLabelByModuleName } from '@/utils/utils'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    ...routes,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  let title = i18n.t('Customer portal')
  if (to.meta && to.meta.title) {
    if (to.name === 'list-view' || to.name === 'detail-view') title += `-${i18n.t(to.meta.title)} ${getUiLabelByModuleName(to.params.module)}`
    else title += `-${i18n.t(to.meta.title)}`
  }
  window.document.title = title
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next(getHomeRouteForLoggedInUser())
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(to => {
  let title = i18n.t('Customer portal')
  if (to.meta && to.meta.title) {
    if (to.name === 'list-view' || to.name === 'detail-view') title += `-${i18n.t(to.meta.title)} ${getUiLabelByModuleName(to.params.module)}`
    else title += `-${i18n.t(to.meta.title)}`
  }
  window.document.title = title
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
