export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/modules/core/error/Error404.vue'),
    meta: {
      title: 'Page not found',
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/register/:username/:token',
    name: 'auth-register',
    component: () => import('@/views/modules/core/authentication/Register.vue'),
    meta: {
      title: 'Sign up',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
    props: true,
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/modules/core/authentication/Login.vue'),
    meta: {
      title: 'Sign in',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/modules/core/authentication/ForgotPassword.vue'),
    meta: {
      title: 'Forgot Password',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/reset-password/:username/:token',
    name: 'auth-reset-password',
    component: () => import('@/views/modules/core/authentication/ResetPassword.vue'),
    meta: {
      title: 'Reset Password',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
    props: true,
  },

  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('@/views/modules/core/authentication/NotAuthorized.vue'),
    meta: {
      title: 'You are not authorized',
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/modules/core/profile/Profile.vue'),
    meta: {
      title: 'Profile',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/modules/core/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
    },
  },
  {
    path: '/list/:module',
    name: 'list-view',
    component: () => import('@/views/modules/ComponentLoader.vue'),
    props: true,
    meta: {
      title: 'List',
    },
  },
  {
    path: '/detail/:module/:id',
    name: 'detail-view',
    component: () => import('@/views/modules/ComponentLoader.vue'),
    props: true,
    meta: {
      title: 'Detail',
    },
  },
  {
    path: '/edit/:module/:id?',
    name: 'edit-view',
    component: () => import('@/views/modules/ComponentLoader.vue'),
    props: true,
    meta: {
      title: 'Edit',
    },
  },
]
