import dateFormat from './date'
import { summaryText, nl2br } from './text'
import currencyFormat from './currency'

export default {
  install(Vue) {
    Vue.filter('dateFormat', dateFormat)
    Vue.filter('summaryText', summaryText)
    Vue.filter('nl2br', nl2br)
    Vue.filter('currencyFormat', currencyFormat)
  },
}
