import api from '@/apis/api'

export default {
  fetchPortalInfo() {
    return api.axiosIns.get('fetchPortalInfo')
  },
  fetchCompanyDetails() {
    return api.axiosIns.get('fetchCompanyDetails')
  },
  fetchModules() {
    return api.axiosIns.get('fetchModules')
  },
  describeModule(params) {
    return api.axiosIns.get('describeModule', {
      params,
    })
  },
  fetchRecords(params, cancelToken) {
    return api.axiosIns.get('fetchRecords', {
      cancelToken,
      params,
    })
  },
  fetchRecord(params) {
    return api.axiosIns.get('fetchRecord', {
      params,
    })
  },
  fetchRelatedModules(params) {
    return api.axiosIns.get('fetchRelatedModules', {
      params,
    })
  },
  fetchRelatedRecords(params) {
    return api.axiosIns.get('fetchRelatedRecords', {
      params,
    })
  },
  fetchHistory(params) {
    return api.axiosIns.get('fetchHistory', {
      params,
    })
  },
  fetchReferenceRecords(params) {
    return api.axiosIns.get('fetchReferenceRecords', {
      params,
    })
  },
  fetchItemRecords() {
    return api.axiosIns.get('fetchItemRecords')
  },
  fetchAnnouncement() {
    return api.axiosIns.get('fetchAnnouncement')
  },
  fetchRecentRecords() {
    return api.axiosIns.get('fetchRecentRecords')
  },
  helpDeskStatistics() {
    return api.axiosIns.get('helpDeskStatistics')
  },
  fetchProfile() {
    return api.axiosIns.get('fetchProfile')
  },
  saveRecord(params) {
    return api.axiosIns.post('saveRecord', params)
  },
  upload(route, params, progress = null) {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    if (progress !== null) {
      config = {
        onUploadProgress: progressEvent => {
          progress(Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          ))
        },
      }
    }
    const formData = new FormData()
    Object.keys(params).forEach(key => {
      formData.append(key, params[key])
    })
    return api.axiosIns.post(route, formData, config)
  },
  downloadFile(params) {
    return api.axiosIns.get('downloadFile', {
      params,
    })
  },
  addComment(comment) {
    return api.axiosIns.post('addComment', {
      comment,
    })
  },
  exportRecords(params) {
    return api.axiosIns.get('exportRecords', {
      params,
    })
  },
  searchRecords(params) {
    return api.axiosIns.get('searchRecords', {
      params,
    })
  },
  getGateways() {
    return api.axiosIns.get('getGateways')
  },
  addPayment(params) {
    return api.axiosIns.post('addPayment', params)
  },
  payNow(params) {
    return api.axiosIns.post('payNow', params)
  },
}
