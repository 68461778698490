export default {
  // Endpoints
  baseURL: process.env.NODE_ENV === 'production' ? '/api' : 'https://vux.crm24.io/api',
  loginEndpoint: '/login',
  registerEndpoint: '/register',
  logoutEndpoint: '/logout',
  forgotPasswordEndpoint: '/forgotPassword',
  resetPasswordEndpoint: '/resetPassword',
  changePasswordEndpoint: '/changePassword',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
}
