import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import * as moment from 'jalali-moment'
import store from '@/store'

export const getModuleIcon = module => {
  const icons = {
    HelpDesk: 'MessageSquareIcon',
    Faq: 'HelpCircleIcon',
    Invoice: 'FileTextIcon',
    Quotes: 'FilePlusIcon',
    Products: 'BoxIcon',
    Services: 'PackageIcon',
    Documents: 'FileIcon',
    Project: 'BriefcaseIcon',
    ProjectTask: 'CheckSquareIcon',
    ProjectMilestone: 'SquareIcon',
    Assets: 'DatabaseIcon',
    ServiceContracts: 'EditIcon',
    Payments: 'DollarSignIcon',
    SalesOrder: 'ShoppingBagIcon',
  }
  return typeof icons[module] !== 'undefined' ? icons[module] : 'GridIcon'
}
export const getNavigationMenus = (modules = []) => {
  const navMenuItems = [
    {
      title: i18n.t('Dashboard'),
      route: { name: 'dashboard', params: {} },
      icon: 'HomeIcon',
    },
  ]
  if (!modules.length) return navMenuItems
  const moreNav = {
    header: 'More',
    icon: 'PlusCircleIcon',
    children: [],
  }
  modules.forEach((module, index) => {
    const navMenu = {
      title: module.uiLabel,
      route: { name: 'list-view', params: { module: module.name } },
      icon: getModuleIcon(module.name),
    }
    if (index < 6) {
      navMenuItems.push(navMenu)
    } else {
      moreNav.children.push(navMenu)
    }
  })
  const navMenuItemsVertical = navMenuItems.concat(moreNav.children)
  navMenuItems.push(moreNav)
  return {
    navMenuItemsVertical,
    navMenuItemsHorizontal: navMenuItems,
  }
}
export const getLabelByModuleName = moduleName => {
  const { modules } = store.state.portal
  const find = modules.find(module => module.name === moduleName)
  return find ? find.label : ''
}
export const getUiLabelByModuleName = moduleName => {
  const { modules } = store.state.portal
  const find = modules.find(module => module.name === moduleName)
  return find ? find.uiLabel : ''
}
export const getSingleLabelByModuleName = moduleName => {
  const { modules } = store.state.portal
  const find = modules.find(module => module.name === moduleName)
  return find ? find.singleLabel : ''
}
export const showToastNotification = ({ text = '', type = 'error' }) => {
  if (!text) return false
  let title
  let icon
  let variant
  if (type === 'error') {
    title = i18n.t('Error')
    icon = 'XCircleIcon'
    variant = 'danger'
  } else {
    title = i18n.t('Success Message')
    icon = 'CheckIcon'
    variant = 'success'
  }
  return Vue.$toast({
    component: ToastificationContent,
    position: 'top-right',
    props: {
      title,
      icon,
      variant,
      text,
    },
  })
}
export const groupBy = key => array => array.reduce(
  (objectsByKeyValue, obj) => ({
    ...objectsByKeyValue,
    [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj),
  }),
  {},
)
export const getInventoryModules = (module = null) => {
  const inventoryModules = ['Quotes', 'Invoice', 'PurchaseOrder', 'SalesOrder']
  if (module) return inventoryModules.includes(module)
  return inventoryModules
}
export const formatLineItems = lineItems => {
  const inventoryLineItems = []
  lineItems.forEach(item => {
    inventoryLineItems.push({
      productid: item.id,
      listprice: item.unitPrice,
      quantity: item.qty,
    })
  })
  return inventoryLineItems
}
export const localeDate = (date = null, format = 'YYYY/MM/DD') => {
  if (date === '') return ''
  let currentDate
  if (date === null) currentDate = moment()
  else currentDate = moment(date)
  return currentDate.format(format)
}
export const moneyFormat = (money, currency = null) => {
  let currencySymbol = currency
  if (currency === null) currencySymbol = store.state.portal.companyDetails.currency
  if (typeof currencySymbol === 'undefined') currencySymbol = ''
  const nf = new Intl.NumberFormat()
  return `${nf.format(money)} ${currencySymbol}`
}
export const numberFormat = (number, decimals, decPoint, thousandsSep) => {
  // eslint-disable-next-line no-param-reassign
  number = (`${number}`).replace(/[^0-9+\-Ee.]/g, '')
  const n = !Number.isFinite(+number) ? 0 : +number
  const prec = !Number.isFinite(+decimals) ? 0 : Math.abs(decimals)
  const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
  const dec = (typeof decPoint === 'undefined') ? '.' : decPoint
  let s = ''
  // eslint-disable-next-line no-shadow
  const toFixedFix = (n, prec) => {
    if ((`${n}`).indexOf('e') === -1) {
      return +(`${Math.round(`${n}e+${prec}`)}e-${prec}`)
    }
    const arr = (`${n}`).split('e')
    let sig = ''
    if (+arr[1] + prec > 0) {
      sig = '+'
    }
    return (+(`${Math.round(`${+arr[0]}e${sig}${+arr[1] + prec}`)}e-${prec}`)).toFixed(prec)
  }
  // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec).toString() : `${Math.round(n)}`).split('.')
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  return s.join(dec)
}
export const commonRelatedModules = (name = null) => {
  const relatedModules = [
    'History',
    'Documents',
  ]
  return module === null ? relatedModules : relatedModules.includes(name)
}
