import { initialAbility } from '@/libs/acl/config'
import router from '@/router'
import ability from '@/libs/acl/ability'
import store from '@/store'
import { showToastNotification } from '@/utils/utils'
import i18n from '@/libs/i18n'
import apiConfig from './apiConfig'

export default class ApiService {
    failedRequests = []

    // Will be used by this service for making API calls
    axiosIns = null

    // jwtConfig <= Will be used by this service
    jwtConfig = { ...apiConfig }

    // For Refreshing Token
    isAlreadyFetchingAccessToken = false

    // For Refreshing Token
    subscribers = []

    constructor(axiosIns, ApiOverrideConfig) {
      this.axiosIns = axiosIns
      this.jwtConfig = { ...this.jwtConfig, ...ApiOverrideConfig }

      // Request Interceptor
      this.axiosIns.interceptors.request.use(
        config => {
          // Get token from localStorage
          const accessToken = this.getToken()

          // If token is present add it to request's Authorization Header
          if (accessToken) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          }
          // eslint-disable-next-line no-param-reassign
          config.baseURL = apiConfig.baseURL
          // eslint-disable-next-line no-param-reassign
          if (config.params) config.params.locale = store.state.appConfig.app.locale
          // eslint-disable-next-line no-param-reassign
          else config.params = { locale: store.state.appConfig.app.locale }
          return config
        },
        error => Promise.reject(error),
      )

      // Add request/response interceptor
      this.axiosIns.interceptors.response.use(
        response => (response && response.data ? response.data.data : response.data),
        error => {
          const { config, response } = error
          const originalRequest = config
          if (!this.axiosIns.isCancel(error)) {
            if (typeof response === 'undefined') {
              this.failedRequests.push(originalRequest)
              if (this.failedRequests.length <= 1) showToastNotification({ text: i18n.t('There is response to your request, please check internet access') })
            } else if (response.status === 401) {
              this.failedRequests.push(originalRequest)
              if (this.failedRequests.length <= 1) showToastNotification({ text: i18n.t('Your session has expired or your token is invalid') })
              this.logoutAction(false)
              const retryOriginalRequest = new Promise(resolve => {
                this.addSubscriber(accessToken => {
                  // Make sure to assign accessToken according to your response.
                  // Check: https://pixinvent.ticksy.com/ticket/2413870
                  // Change Authorization header
                  originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                  resolve(this.axiosIns(originalRequest))
                })
              })
              return retryOriginalRequest
            } else if (typeof error.response.data !== 'undefined') {
              showToastNotification({ text: error.response.data.error })
            }
          }
          return Promise.reject(error)
        },
      )
    }

    addSubscriber(callback) {
      this.subscribers.push(callback)
    }

    getToken() {
      return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
    }

    setToken(value) {
      localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    }

    register(...args) {
      return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
    }

    login(...args) {
      return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
    }

    logout() {
      return this.axiosIns.post(this.jwtConfig.logoutEndpoint)
    }

    async logoutAction(logoutRequest = true) {
      if (logoutRequest) await this.logout()
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(this.jwtConfig.storageTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      ability.update(initialAbility)
      // Redirect to login page
      await router.push({ name: 'auth-login' })
    }

    forgotPassword(...args) {
      return this.axiosIns.post(this.jwtConfig.forgotPasswordEndpoint, ...args)
    }

    resetPassword(...args) {
      return this.axiosIns.post(this.jwtConfig.resetPasswordEndpoint, ...args)
    }

    changePassword(...args) {
      return this.axiosIns.post(this.jwtConfig.changePasswordEndpoint, ...args)
    }
}
