import { $themeConfig } from '@themeConfig'

const appConfig = JSON.parse(localStorage.getItem('appConfig')) || {}

export default {
  namespaced: true,
  state: {
    app: {
      appName: appConfig.app ? appConfig.app.appName : $themeConfig.app.appName, // Will update name in navigation menu (Branding)
      // eslint-disable-next-line global-require
      appLogoImage: appConfig.app ? appConfig.app.appLogoImage : $themeConfig.app.appLogoImage, // Will update logo in navigation menu (Branding)

      locale: appConfig.app && appConfig.app.locale ? appConfig.app.locale : $themeConfig.app.locale,
    },
    layout: {
      isRTL: appConfig.app ? appConfig.layout.isRTL : $themeConfig.layout.isRTL,
      skin: appConfig.app ? appConfig.layout.skin : $themeConfig.layout.skin,
      routerTransition: appConfig.app ? appConfig.layout.routerTransition : $themeConfig.layout.routerTransition,
      type: appConfig.app ? appConfig.layout.type : $themeConfig.layout.type,
      contentWidth: appConfig.app ? appConfig.layout.contentWidth : $themeConfig.layout.contentWidth,
      menu: {
        hidden: appConfig.app ? appConfig.layout.menu.hidden : $themeConfig.layout.menu.hidden,
      },
      navbar: {
        type: appConfig.app ? appConfig.layout.navbar.type : $themeConfig.layout.navbar.type,
        backgroundColor: appConfig.app ? appConfig.layout.navbar.backgroundColor : $themeConfig.layout.navbar.backgroundColor,
      },
      footer: {
        type: appConfig.app ? appConfig.layout.footer.type : $themeConfig.layout.footer.type,
      },
    },
  },
  getters: {},
  mutations: {
    TOGGLE_RTL(state) {
      state.layout.isRTL = !state.layout.isRTL
      document.documentElement.setAttribute('dir', state.layout.isRTL ? 'rtl' : 'ltr')
    },
    UPDATE_SKIN(state, skin) {
      state.layout.skin = skin
      // Update DOM for dark-layout
      if (skin === 'dark') document.body.classList.add('dark-layout')
      else if (document.body.className.match('dark-layout')) document.body.classList.remove('dark-layout')
    },
    UPDATE_ROUTER_TRANSITION(state, val) {
      state.layout.routerTransition = val
    },
    UPDATE_LAYOUT_TYPE(state, val) {
      state.layout.type = val
    },
    UPDATE_CONTENT_WIDTH(state, val) {
      state.layout.contentWidth = val
    },
    UPDATE_NAV_MENU_HIDDEN(state, val) {
      state.layout.menu.hidden = val
    },
    UPDATE_NAVBAR_CONFIG(state, obj) {
      Object.assign(state.layout.navbar, obj)
    },
    UPDATE_FOOTER_CONFIG(state, obj) {
      Object.assign(state.layout.footer, obj)
    },
    UPDATE_APP_LOCALE(state, val) {
      state.app.locale = val
      document.documentElement.setAttribute('lang', val)
    },
  },
  actions: {},
}
