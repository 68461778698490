export default {
  namespaced: true,
  state: {
    companyDetails: {},
    fetchModules: false,
    modules: [],
    navMenuItemsHorizontal: [],
    navMenuItemsVertical: [],
    userData: JSON.parse(localStorage.getItem('userData')),
    formData: {},
    loaders: {
      exportData: false,
    },
  },
  mutations: {
    UPDATE_COMPANY_DETAILS(state, companyDetails) {
      state.companyDetails = companyDetails
    },
    UPDATE_MODULES(state, modules) {
      state.modules = modules
      state.fetchModules = true
    },
    UPDATE_VERTICAL_MENU_ITEMS(state, items) {
      state.navMenuItemsVertical = items
    },
    UPDATE_HORIZONTAL_MENU_ITEMS(state, items) {
      state.navMenuItemsHorizontal = items
    },
    UPDATE_USER_DATA(state, userData) {
      state.userData = userData
    },
    UPDATE_FORM_DATA(state, formData) {
      state.formData = formData
    },
    UPDATE_LOADERS(state, loaders) {
      state.loaders = loaders
    },
  },
  actions: {},
}
