import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const setUserData = userData => store.commit('portal/UPDATE_USER_DATA', userData)

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return getUserData() && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getHomeRouteForLoggedInUser = () => (isUserLoggedIn() ? '/' : { name: 'auth-login' })
