import { isUserLoggedIn } from '@/auth/utils'
import api from '@/apis/portal'
import store from '@/store'
import { getInventoryModules, getNavigationMenus, showToastNotification } from '@/utils/utils'

export const coreMethods = {
  data() {
    return {
      skipFields: ['id', 'source'],
    }
  },
  methods: {
    getModuleByName(moduleName, key = '') {
      const find = store.state.portal.modules.find(module => module.name === moduleName)
      return find && key ? find[key] : find
    },
    getComponent($this, componentName, module = '', defaultComponent = '') {
      let component = componentName
      if (module) {
        component = module + componentName
        if (!$this.$options.components[component]) {
          if (getInventoryModules(module)) {
            component = `Inventory${componentName}`
          }
        }
      }
      if (!$this.$options.components[component]) component = componentName
      if ($this.$options.components[component]) return component
      return defaultComponent
    },
    getCellType(type, module) {
      return this.getComponent(this, `${type.charAt(0).toUpperCase()}${type.slice(1)}CellType`, module)
    },
    getDisplayType(type, module) {
      return this.getComponent(this, `${type.charAt(0).toUpperCase()}${type.slice(1)}DisplayType`, module)
    },
    getRelatedWidget(name, module) {
      return this.getComponent(this, `${name.charAt(0).toUpperCase()}${name.slice(1)}RelatedWidget`, module)
    },
    getUiType(type, module) {
      return this.getComponent(this, `${type.charAt(0).toUpperCase()}${type.slice(1)}UiType`, module)
    },
    createNavigationMenus() {
      return new Promise((resolve, reject) => {
        if (isUserLoggedIn() && !store.state.portal.fetchModules) {
          const items = getNavigationMenus()
          store.commit('portal/UPDATE_VERTICAL_MENU_ITEMS', items)
          store.commit('portal/UPDATE_HORIZONTAL_MENU_ITEMS', items)
          api.fetchModules().then(data => {
            if (data.modules) {
              const modules = data.modules.sort((a, b) => a.order - b.order)
              store.commit('portal/UPDATE_MODULES', modules)
              const { navMenuItemsVertical, navMenuItemsHorizontal } = getNavigationMenus(modules)
              store.commit('portal/UPDATE_VERTICAL_MENU_ITEMS', navMenuItemsVertical)
              store.commit('portal/UPDATE_HORIZONTAL_MENU_ITEMS', navMenuItemsHorizontal)
            }
            resolve()
          }).catch(err => {
            reject(err)
          })
        } else {
          resolve()
        }
      })
    },
    fetchPortalInfo() {
      api.fetchPortalInfo().then(data => {
        if (data && data.logo !== '') store.commit('app/UPDATE_LOGO_SRC', `data:image/jpeg;base64,${data.logo}`)
        if (data && data.title !== '') store.commit('app/UPDATE_TITLE', data.title)
      })
    },
    fetchCompanyDetails() {
      if (isUserLoggedIn()) {
        api.fetchCompanyDetails().then(data => {
          store.commit('portal/UPDATE_COMPANY_DETAILS', data)
        })
      }
    },
    downloadFile(document, parentId = null) {
      if (document.filelocationtype === 'E') {
        window.open(document.filename, '_blank').focus()
      } else {
        const params = {
          module: 'Documents',
          recordId: document.id,
        }
        if (parentId !== null) {
          params.parentId = parentId
        }
        api.downloadFile(params).then(data => {
          const fileName = data.filename // if you have the fileName header available
          const linkSource = `data:${data.filetype};base64,${data.filecontents}`
          this.createDownloadLink(fileName, linkSource)
        })
      }
    },
    createDownloadLink(fileName, linkSource) {
      const downloadLink = document.createElement('a')
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    },
    showToastNotification({ text = '', type = 'error' }) {
      showToastNotification({ text, type })
    },
    showSuccessNotification(text) {
      this.showToastNotification({ text, type: 'success' })
    },
    showErrorNotification(text) {
      this.showToastNotification({ text })
    },
  },
}
export const _ = null
